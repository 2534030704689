import React from "react";
import "./soon.scss";
import Comingsoon from "../../media/banner_soon.webp";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Promo,%20Brosur,%20dan Katalog%20produk%20terbaru%20Summarecon%20Serpong%20https://marketingsummaserpong.com//&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const soon = () => {
  return (
    <div id="soon" className="container-soon">
      <div className="gambar-soon">
        <img src={Comingsoon} alt={Comingsoon} />
      </div>
      <div className="button">
        <div className="container-wa">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default soon;
