import React from "react";
import "./footer.scss";
import logo from "../../media/Logo.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-sms" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <p>
              Ruko Boulevard CBD, Jl. Gading Serpong Boulevard, Tangerang,
              Banten 15810
            </p>
          </div>
          <div className="contact">Contact us : +6285215127964</div>
          <div className="powered">Powered by Linktown</div>
        </div>
      </div>
    </div>
  );
};

export default footer;
